import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "./hr-chatbot";
import {GoToPopup} from "../homepage"
import {CookiesPoup} from "../homepage"


import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../assets/images/market_ui/slack/auth0_side.png");

const image1 = require("../../assets/images/blog_7/b7_1.webp");
const image2 = require("../../assets/images/blog_7/b7_2.png");
const image3 = require("../../assets/images/blog_7/b7_3.jpg");
const image4 = require("../../assets/images/blog_7/b7_4.jpg");
const image5 = require("../../assets/images/blog_7/b7_5.jpg");
const image6 = require("../../assets/images/blog_7/b7_6.png");

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Integrate Auth0 with chatbot and automate IT support | Workativ Blog"
        description="Discover how Auth0 users automate IT tasks like password resets and access management using Workativ's chatbot. Streamline workflows with Auth0 IT chatbot."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            How to build integrations with Auth0 using Workativ
                            Assistant
                          </h1>
                          <img  src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          In the rush to invest in tools that provide more
                          insights about the customer journey and help provide
                          better customer experience, businesses may have
                          overlooked another powerful tool for improving the
                          customer experience—the employee experience also
                          referred to as EX. Companies that actively work to
                          engage their employees outperform their competitors by
                          <a href="https://www.mtievents.com/blog/how-employee-experience-improves-customer-experience/">
                            &nbsp;147%.
                          </a>{" "}
                          That’s a huge opportunity for profit and though the
                          connection may not be apparent at first, it’s easy to
                          see the correlation with a closer look.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          So giving your employees the employee experience
                          they’d love from day 1 will reap you immense benefits.
                          How you ask? You can start with automating your IT
                          Service Management processes so that{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            employees can self-serve themselves.
                          </a>{" "}
                          If your business is using an ITSM tool like Auth0 for
                          authentication and authorization services, it is
                          high-time you automated the tasks on it with an
                          intelligent solution like Workativ Assistant.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a no-code AI-powered platform
                          that lets anyone with no coding knowledge build
                          contextual chatbots with automated workflows to
                          automate employee support. Workativ Assistant’s
                          chatbots are easy to add to your business’{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            &nbsp;Slack
                          </a>{" "}
                          or{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Microsoft Teams.
                          </a>{" "}
                          Teams workspace so that your employees can self-serve
                          their IT needs like a password reset or an account
                          unlock.
                        </p>
                        <h5 class="font-section-sub-header-small line-height-2">
                          Deliver IT Process Automation on Password Reset, User
                          Provisioning and Access Management via Auth0 Workflow
                          Automation
                        </h5>
                        <img loading = "lazy" src={image2} className="mb-5"></img>
                        <p class="font-section-normal-text line-height-2">
                          Analysts have sited that around{" "}
                          <a href="https://www.linkedin.com/pulse/does-password-reset-service-desk-cost-us-money-yes-wake-vijay-shankar/">
                            30% – 50% of help desk calls
                          </a>{" "}
                          are related to password reset. Moreover, laws and
                          regulations such as PCI DSS, Sarbanes-Oxley, and HIPAA
                          have reviewed password requirements and now encourage
                          or demand greater complexity.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Resetting passwords wastes an enormous amount of time
                          and money resulting in end-user frustration, employee
                          dissatisfaction, and compliance vulnerabilities.
                          Empower your employee by allowing them to{" "}
                          <a href="https://workativ.com/use-cases/reset-password-automation">
                            reset their passwords
                          </a>{" "}
                          from within your business’ chat hub like Slack or
                          Microsoft Teams with Workativ Assistant’s Auth0
                          chatbot.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Employees can easily make use of Workativ Assistant’s
                          Auth0 chatbot on their Slack or Microsoft Teams to
                          reset their password in a jiff by having a quick chat
                          with the chatbot, thereby increasing their productive
                          hours.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          And to add on to password resets,{" "}
                          <a href="https://workativ.com/use-cases/user-provisioning-automation">
                            user provisioning&nbsp;
                          </a>
                          too can be tedious for IT help desk agents. Creating
                          profiles in multiple systems involves multiple people
                          and departments, and there are many places where
                          bottlenecks can form. HR and IT have to work together
                          for the right things to happen at the right time, and
                          the manual workload for IT is significant.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Auth0 user provisioning automation via Workativ
                          Assistant’s Auth0 chatbot takes a lot of the pain out
                          of employee{" "}
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            onboarding
                          </a>{" "}
                          and{" "}
                          <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                            offboarding.
                          </a>{" "}
                          Help desk agents can easily create a role, add a user,
                          add a user to a role, retrieve details about a user or
                          delete a user on Auth0 just by having a quick
                          conversation with Workativ Assistant’s chatbot on
                          their Slack or Microsoft Teams and telling the chatbot
                          what to do. Workativ Assistant’s Auth0 chatbot then
                          does what the help desk agent wants them to do in the
                          blink of an eye, letting the help desk agent focus on
                          complex tasks that require human intervention.
                        </p>
                      </div>
                      <div>
                        <h5 class="font-section-sub-header-small">
                          Intelligent IT Process Automation using Auth0 +
                          Workativ + Other business apps
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can combine Auth0 and Workativ Assistant with any
                          number of apps creating a synergy between them via
                          efficient automation workflows. Let’s take a look at
                          some of the apps that you can combine with Workativ
                          Assistant and Auth0.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Auth0 Office 365 Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s Self-Service Password Reset
                          (SSPR) via contextual chatbots combined with Auth0 and
                          Office 365 gives users the ability to change or reset
                          their password, with no administrator or help desk
                          involvement. If a user forgot their password, they can
                          follow prompts on Workativ Assistant’s chatbot to
                          reset it themselves and get back to work. This ability
                          reduces help desk calls and loss of productivity when
                          a user can’t sign in to their device or an
                          application.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For this, you can set up an Auth0 automation such
                          that, after you log in to your Auth0 account with your
                          Office 365 credentials via a Single Sign-On (SSO) and
                          set up the automation, the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            Workativ Assistant chatbot
                          </a>{" "}
                          when brought up will ask you details regarding your
                          account to verify it’s you and then it will validate
                          those details and allow you to reset your password on
                          the chatbot screen itself.{" "}
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Auth0 Jira Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          New developer joining your company? No worries. You
                          can set up a Workativ Assistant automation by
                          <a href="https://workativ.com/workflow-automation-platform/integrations/jira+auth0">
                            &nbsp;connecting your Jira and Auth0 accounts
                          </a>{" "}
                          such that, after retrieving details about the
                          developer from Auth0, the details are used to create a
                          Jira account for the developer and add them to your
                          company’s Jira workspace.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Auth0 Slack Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whenever there’s a new hire, help desk employees have
                          to create an email ID for the hire and convey the same
                          to the hire. This can be a bit frustrating for help
                          desk agents when the hiring pool is large and
                          frequent.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          By connecting your business’ Auth0 and Slack accounts,
                          you can set up an automation such that, after creating
                          an email ID for a new hire on Auth0, the same gets
                          conveyed to the hire on their Slack by Workativ
                          Assistant’s chatbot.
                        </p>
                        <img loading = "lazy" src={image3} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          How you can easily create an Auth0 chatbot using
                          Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ Assistant and Auth0 and your
                          existing business apps can do for you, let’s see how
                          you can set up an Auth0 chatbot with Workativ
                          Assistant.
                        </p>
                        
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading = "lazy" src={image4} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading = "lazy" src={image5} className="mb-3"></img>
                        <img loading = "lazy" src={image6} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Perks of augmenting Auth0 with Workativ Assistant
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Let’s take a look at what the Auth0 integration with
                        Workativ Assistant means for you.
                      </p>
                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. On-the-go conversational IT support that employees
                          would love
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            {" "}
                            Workativ Assistant’s conversational AI/chatbot
                          </a>{" "}
                          offers seamless 24×7 Auth0 related actions that your
                          employees/help desk agents can ask the chatbot to do.
                          Whether they are in the workplace or on the road,
                          employees can reset their password or do anything on
                          Auth0 with Workativ Assistant.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Creating a Synergy between your existing Business
                          Apps
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Like the{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/auth0+slack">
                            Auth0 Slack integration
                          </a>{" "}
                          that works synergistically, you can combine your other
                          business apps with Auth0 creating an automated
                          workflow that does much more with those apps in just a
                          single run.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. All from the comfort of your chat channel
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s chatbots integrate seamlessly
                          with your Slack or Microsoft Teams workspace so that
                          your employees don’t have to leave their chat hub to
                          self-serve an IT issue. This gets rid of the
                          frustration that comes with contacting the help desk
                          or logging in and out of the service desk portal
                          whenever an employee wants to create an IT support
                          ticket for a simple thing like a password reset.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ Assistant? Try
                          Workativ Assistant today by signing up for a FREE
                          trial at
                          <a href="https://workativ.com/">
                            &nbsp;workativ.com
                          </a>{" "}
                          and experience the difference yourself.
                        </p>
                      </div>

                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul" style={{ width: "100%", float: "left" }}>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="https://workativ.com/conversational-ai-platform/auth0-chatbot">
                        <img src={topImage} alt="auth0 chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <OnScrollPopup />
            <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Auth0 Chatbot </h4>
      <button>
        <a
          href="https://workativ.com/conversational-ai-platform/auth0-chatbot"
          alt="auth0 chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
